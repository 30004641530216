import { inject, Injectable } from '@angular/core';
import { Relay } from '@signalwire/js';
import { AuthService } from 'src/app/services/auth.service';
import {
  Account,
  APIService,
  CreateCallInput,
  CreateTransactionInput,
  Currency,
  UpdateCallInput,
  UpdateTransactionInput
} from '../../../API.service';
import { AWSLambda } from '../../../AWSLambda.service';
import { Balance, Rate, RateCharge } from '../../rates/rates.model';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { CallsComponent } from '../calls.component';
import { BehaviorSubject } from 'rxjs';
import { Auth } from 'aws-amplify';



@Injectable({
  providedIn: CallsComponent,
})
export class SignalWireService {
  private sw_outbound: any;
  private client: any;
  public currentCall: any;
  private historyRecord: any;
  private callidentifier: any;
  public validCountryphoneNumber : boolean = false;
  public callstate:string = "inactive"
  public time: number = 0;
  private newtoken:string = "";
  private refrestoken:string = "";
  private balanceSubject = new BehaviorSubject<number>(0);
  public balance$ = this.balanceSubject.asObservable();
  private user = inject(AuthenticatorService).user;
  auth = inject(AuthService);
  
  constructor(
    private lambda: AWSLambda,
    private api: APIService,
    private authService: AuthService,
    private authenticatorSvc: AuthenticatorService
  ) { }

  async init(createcall: any) {
    // const jwt = await this.lambda.getLambdaFunction(
    //   'lambda',
    //   '/signalwire/jwt'
    // )
    await this.auth.refresh();
    this.user = await Auth.currentAuthenticatedUser();


    const countryAllowedNumber = await this.lambda.getLambdaFunction('lambda', '/country/allowed/get', {
      queryStringParameters: {
        phone_number: this.user.attributes?.phone_number
      },
    });

    this.client = new Relay({
      host: createcall.relay,
      project: createcall.project_id,
      token: createcall.jwt_token,
      // host: "https://digitalservices.globalcarrier.telekom.com/api/services/cpaas",
      // project: "4fb4dab3-de9a-496c-92d6-8e07b12b8e77",
      // token: "T0RJeU1EUXdabUV0TlRJeU15MDBaV1UyTFRrd1pHRXRNMlU0WkRJd09XTTFPV0ZqUURFek1tVTFNalkzTFROaVl6VXRORFk0WWkwNU9EQmtMV0ptWmpabU1URXhNekpoTXc9PToxYWFkNzk4Ni0xN2NlLTRiMzItYmYzMS1lNjI2MjI3YzAzY2M=",
      //status_callback:'https://api.dev.zendy.tel/call/webhook',
    });
    this.refrestoken=createcall.refresh_token
    this.callidentifier = createcall.identifier
  

   

    this.validCountryphoneNumber = countryAllowedNumber;

    this.client.iceServers = [{ urls: ['stun:stun.l.google.com:19302'] }];
    this.client.remoteElement = 'remoteVideo';
    this.client.localElement = 'localVideo';
    this.client.enableMicrophone();
    this.client.disableWebcam();

    this.client.remoteElement = 'remoteVideo';
    this.client.localElement = 'localVideo';

  }

  connected() {
    return this.client.connected;
  }

  async connect() {
    try {
        await this.client.connect();
    } catch (error) { 
      console.error("Fallo de conexión inicial:", error);
        const history: UpdateCallInput = {
          id: this.historyRecord.id,
          note:'error: ' + JSON.stringify(error),
          state: 'error'
        };
        this.historyRecord = await this.api.UpdateCall(history);
    }
  }  
  


  async reconnect() {
    try {
      await this.client.reconnect();
    } catch (e) { 
      const history: UpdateCallInput = {
        id: this.historyRecord.id,
        note:'error: ' + JSON.stringify(e),
        state: 'error'
      };
      this.historyRecord = await this.api.UpdateCall(history);
    }
  }


  async disconnect() {
    try {
      await this.client.disconnect();
    } catch (e) { 
      
    }
  }

  updatetime(tiempo: number) {
    this.time = tiempo;
  }

  async getCallInfo(identifier: string, idcall: string) {

    const callKeys = Object.keys(this.client.calls);
    if (callKeys.length > 0) {
      // Acceder a la primera clave
      const firstKey = callKeys[0];
      this.callstate = this.client.calls[firstKey].state;
    }
    // console.log("ESTADO",this.callstate)
    // console.log("SW",identifier)
    
    
    const callInfo = await this.lambda.getLambdaFunction(
      'lambda',
      //'/signalwire/call',
      '/call/realtime',
      {
        queryStringParameters:
        {
          identifier: identifier,
          callstate: this.callstate,
          refreskt:this.refrestoken
        } 
      }
    ); 

    this.balanceSubject.next(parseFloat(parseFloat(callInfo.saldo).toFixed(2)));
    return callInfo;
  }

  //este mismo calculo se hace en backend
  async updateCallAndTransactionInfo(pricing: Rate, time: number, notEnoughtBalance: boolean = false): Promise<RateCharge> {


    
    // Hasta que no se cuelge la llamada es cero.
    const info = await this.getCallInfo(this.historyRecord.identifier, this.historyRecord.id); //The segment
    // if billing_ms == 0 then do not price this call.

    //console.log("info", info)
    const realTime = (info.billing_ms == 0 || info.billing_ms == null) ? time : (info.billing_ms / 1000)
    const realCharge = (info.billing_ms == 0 || info.billing_ms == null) ? (time / 60) * pricing.pricing[0].amountPerMinuteEuro : ((info.billing_ms / 1000 / 60) * pricing.pricing[0].amountPerMinuteEuro)


    const infoJson = JSON.stringify({
      labelDestinationNumber: this.historyRecord.destination,
      labelTime: realTime,
      labelCharge: realCharge,
      labelCurrency: 'EUR'
    })

    const history: UpdateCallInput = {
      id: this.historyRecord.id,
      hangup: true,
      info: infoJson,
      note:'updated',
      about: JSON.stringify(info as string), //He cambiado este atributo a about, en vez de info, para no sobreescribirlo.
    };
    this.historyRecord = await this.api.UpdateCall(history);

    //console.log("continuar llamada " +info.Continuar)  
    if(this.callstate === "active")
      {
       
        if (info.Continuar)
          {  
            if(info.jwt!= null)
              {
                //console.log("valore de jwt: "+info.jwt)
                //this.client.token = info.jwt;
                this.newtoken = info.jwt;
                await this.client.refreshToken(info.jwt).catch(console.error)
                //await this.client.refreshToken(this.refrestoken).catch(console.error)
                this.refrestoken = info.torefreshjwt
                //console.log("token refrescado: ")
               
              }
          }
        if (!info.Continuar)
          {  
              this.hangup(info.precioMinuto,info.realTime, true);
              this.disconnect();
          }
      }

    return {
      time: realTime,
      charge: realCharge,
      notEnoughtBalance: notEnoughtBalance
    } as RateCharge

  }


  onEventListener(eventName: string, handleNotification: any) {
    this.client.on(eventName, handleNotification);
    this.client.on('signalwire.notification', async (notification: { type?: any; call?: any; }) => {
      // console.log('GLOBAL notification', notification)
      //console.log('log de notificaciones: ' + notification.type)
      switch (notification.type) {
        // case 'callUpdate':
        //   const { call } = notification
        //   if (call.state === 'destroy') {
        //     this.client({ call: null })
        //   } else {
        //     this.client({ call })
        //   }
        //   break
        // case 'participantData':
        //    Caller's data like name and number to update the UI. In case of a conference call you will get the name of the room and the extension.
        //  break
        case 'refreshToken':
            //console.log("necesitas refrecar el token " )
            const jwt: any = await this.lambda.getLambdaFunction(
              'lambda',
              '/signalwire/jwt',
              { queryStringParameters: 
                { 
                  refreskt:this.refrestoken,
                  id:this.historyRecord.id
                } 
              }
            ); 
            
            this.refrestoken=jwt.refresh_token
            await this.client.refreshToken(jwt.jwt_token).catch(console.error)
            //console.log("token actualizado " )
        break
      }
    })
    

    this.client.on('verto.disconnected', async () => {
      console.log("Desconectado. Intentando reconectar...");
      try {
          //await this.connectWithRetry();
      } catch (error) {
          console.error("No se pudo reconectar después de la desconexión:", error);
      }
    });
    
    this.client.on('verto.error', (error: any) => {
        console.error("Error de SignalWire Verto:", error);
    });
    //this.refreshToken(eventName,handleNotification);
  }

  // async refreshToken(name: string,manejo: any){
  //   console.log("nombre del evento "+name)
  //   console.log("manejo del evento "+manejo)
  //   switch (name) {
  //     case 'refreshToken':
  //       console.log("ver si el token ya esta lleno "+this.newtoken)
  //       await this.client.refreshToken(this.newtoken).catch(console.error)
  //     break
  //   }

  // }

  async call(call: SignalWireCall,countrymask:string,createCall:any) {
    //const signalwire_verified_phone_number = (this.authenticatorSvc.user.attributes?.['custom:signalwire_ok'] == this.authenticatorSvc.user.attributes?.phone_number) ? true : false
    let callerNumber;
    
    //console.log('valid number: ' + this.validCountryphoneNumber)
    //console.log('verify number: ' + signalwire_verified_phone_number)

    if (this.validCountryphoneNumber) {
      callerNumber = this.user.attributes?.phone_number
      //callerNumber = this.authenticatorSvc.user.attributes?.phone_number
    } else {
         callerNumber = call.defaultCallerNumber;
    }

    //aca lo diferente
    //console.log('creando llamada')
    this.currentCall = await this.client.newCall({
      destinationNumber: call.destinationNumber.replace(/\s/g, ''),
      id:createCall.identifier,
      audio: true,
      video: false,
      callerNumber: callerNumber
    });
    //console.log('llamada creada')


    this.historyRecord = { 
      id: createCall.identifier,
      identifier: createCall.identifier,
      countryMask: countrymask,
      destinationNumber: call.destinationNumber.replace(/\s/g, ''),
      labelDestinationNumber: call.labelDestinationNumber,
      direction: this.currentCall.direction,
      resource: 'signalwire/' + this.currentCall.session.options.project,
      //owner: this.authService.getUser().username ?? '',
      owner: this.user.username ?? '',
      hangup: false,
      state: this.currentCall.state,
      token: this.client.token
    };
  }

  async dtmf_on_call(value: string){
    this.currentCall.dtmf(value)
  }

  async mute_audio() {
    this.currentCall.muteAudio();
  }
  async unmute_audio() {
    this.currentCall.unmuteAudio();
  }
  async deafCall() {
    this.currentCall.deaf();
  }
  async undeafCall() {
    this.currentCall.undeaf();
  }

  async setAudioDevice(id: string) {
    const success = await this.currentCall.setAudioOutDevice(id)
    if (success) {
      console.log('Audio redirect to ' + id)
    } else {
      console.log('The browser does not support the .setSinkId() API..')
    }
  }

  async createTransaction(data: CreateTransactionInput) {
    await this.api.CreateTransaction(data)
  }

  async hangCallIfNoBalance(balance: Balance, pricing: Rate, time: number) {

    //console.log(balance.amount)
    const neededBalance = balance.amount - (pricing.pricing[0].amountPerMinuteEuro / 1.85) // Un poco más de 35 segundos.
    //console.log(neededBalance)

    if (neededBalance < 0) {
      this.hangup(pricing, time, true)
    }
  }



  async hangup(pricing: Rate, time:number, notEnoughtBalance:boolean = false): Promise<RateCharge> {
    
    if (this.currentCall) {
      try {
        await this.currentCall.hangup();
        //await this.delay(4000);
        //await this.getCallInfo(this.historyRecord.idcall,this.historyRecord.idcall)
        const update = await this.updateCallAndTransactionInfo(pricing, time, notEnoughtBalance)
        const history: UpdateCallInput = {
          id: this.historyRecord.id,
          note:'user ended',
          state: 'hangup'
        };
        this.historyRecord = await this.api.UpdateCall(history);
        this.client.disconnect();
        return update;

      } catch (e) {
        const history: UpdateCallInput = {
          id: this.historyRecord.id,
          note:'error: ' + JSON.stringify(e),
          state: 'error'
        };
        this.historyRecord = await this.api.UpdateCall(history);
        this.client.disconnect();
        return {
          time: 0,
          charge: 0
        } as RateCharge
      }
    }
    return {
      time: 0,
      charge: 0
    } as RateCharge
    this.client.close
  }

  delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
  }
}



export class SignalWireCall {
  __typename: string = 'Call';
  destinationNumber!: string;
  audio: Boolean = true;
  video: Boolean = false;
  labelDestinationNumber: string = "NA";
  defaultCallerNumber: string = '';
}

